import { MutableRefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import { slideFromPaddedBottom } from '@utils/motions';
import { Button, SwatchController } from '@components';
import { NEW_COLORS, PRODUCT_TYPES } from '@constants';
import { VariantControl } from '@ts/product';
import { frameSwatchesProps } from '@ts/components';
import styles from './ProductViewCTA.module.scss';

type FloatingCTANewProps = {
	ctaRef: MutableRefObject<HTMLButtonElement>;
	buttonProps: Parameters<typeof Button>[0] & {
		hasSubtotal?: boolean;
		isLoading?: boolean;
		availableForSale?: boolean;
		onClick?: (ref?: React.MutableRefObject<HTMLButtonElement | null>) => void;
	};
	name: string;
	primaryController?: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	collectionController?: VariantControl & {
		frameSwatches?: frameSwatchesProps;
	};
	productType: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
};

function FloatingCTANew({
	ctaRef,
	buttonProps,
	name,
	primaryController,
	collectionController,
	productType,
}: FloatingCTANewProps) {
	const classes = cn(styles['cta'], buttonProps.extraClasses);
	const [isButtonSticky, setIsButtonSticky] = useState(true);
	const FloatingCTANew = useRef<HTMLButtonElement>(null);

	const isBaseFrame = productType.includes(PRODUCT_TYPES.BASE_FRAME);

	const controller = isBaseFrame ? primaryController : collectionController;

	const filteredOptions = isBaseFrame
		? primaryController?.options?.filter(option => !NEW_COLORS.includes(option as (typeof NEW_COLORS)[number]))
		: collectionController.options;

	useEffect(() => {
		const button = ctaRef?.current;
		if (!button) return;
		const observerOptions = {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		};

		const handleIntersection = entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setIsButtonSticky(false);
				} else {
					setIsButtonSticky(true);
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersection, observerOptions);
		observer.observe(button);

		return () => {
			observer.unobserve(button);
		};
	}, []);

	return (
		<PortalRoot {...(typeof document !== 'undefined' && { container: document.getElementById('__next') })}>
			<AnimatePresence>
				{isButtonSticky && (
					<>
						<m.div
							className={styles['stickyButton--New']}
							data-floating-cta={buttonProps.label}
							{...slideFromPaddedBottom}
						>
							<SwatchController
								alignment='left'
								options={filteredOptions}
								callback={controller?.callback}
								selected={controller?.selected}
								type={controller?.controllerType as Exclude<typeof controller.controllerType, 'shape'>}
								title={controller?.title}
								label={isBaseFrame ? 'Base Frame Color' : undefined}
								name={name}
								frameSwatches={!isBaseFrame ? collectionController?.frameSwatches : undefined}
								excludeColorLabel={
									isBaseFrame ? !filteredOptions.includes(primaryController.selected) : undefined
								}
							/>
							<Button
								extraClasses={classes}
								size={'medium'}
								color='green'
								fullWidth
								onClick={() => buttonProps.onClick(FloatingCTANew)}
								withChevron={buttonProps.withChevron}
								chevronDirection='right'
								withPrice={buttonProps?.hasSubtotal}
								price={buttonProps.price}
								label={buttonProps.label}
								disabled={buttonProps.disabled || !buttonProps.availableForSale}
								ref={FloatingCTANew}
								{...buttonProps.dataTags}
							/>
						</m.div>
					</>
				)}
			</AnimatePresence>
		</PortalRoot>
	);
}

export default FloatingCTANew;
