import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { NormalizedCartLine, Bundle } from '@ts/cart';
import { useBFContext } from '@context';
import { generateBundle } from '@utils/generate-bundle';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import { SUBMISSION_METHODS } from '@constants';
import { useProductQuery } from '@services/shopify';
import { useHasMounted } from '@utils/hooks';
import { RX_TYPE_HANDLE_MAP } from '@utils/constants/base-skus';

const useProtectYourLenses = () => {
	const { query, locale } = useRouter();
	const isMounted = useHasMounted();
	const [items, setItems] = useState<NormalizedCartLine[]>([]);
	const [bundle, setBundle] = useState<Bundle | null>(null);

	const { data: pairCare, isLoading: isPairCareLoading } = useProductQuery(
		'pair-care',
		{},
		{ queryRefreshVars: [locale], locale }
	);

	const buildFlow = useBFContext();
	const demo = query.demo as string;
	const {
		BaseFrameVariant: {
			data: { variantBySelectedOptions },
		},
		rxType,
		pairCareItem,
		setPairCareItem,
	} = buildFlow || {};

	const cartMutationLineItems = useMemo(() => {
		return generateBundle({
			demo,
			editingBundleKey: (query[BUNDLE_KEY_QUERY] as string) ?? null,
			submissionMethod: SUBMISSION_METHODS.REMIND,
			...buildFlow,
		});
	}, [buildFlow.selectedLenses, buildFlow.frame, demo, query]);

	useEffect(() => {
		const loadItems = async () => {
			const items = await cartMutationLineItems;
			const normalizedCartItems: NormalizedCartLine[] = items.map(({ customAttributes, variant }) => ({
				id: null,
				variant,
				properties: customAttributes
					? Object.assign({}, ...customAttributes.map(({ key, value }) => ({ [key]: value })))
					: null,
				title: null,
				discountAllocations: null,
				quantity: 1,
				optimistic: true,
			}));

			setItems(normalizedCartItems);
		};
		loadItems();
	}, [cartMutationLineItems]);

	useEffect(() => {
		const buildFlowPairCareAdapter = () => {
			const currentLineItem: NormalizedCartLine = {
				...items[0],
				variant: {
					...items[0]?.variant,
					option: variantBySelectedOptions.title,
					price: variantBySelectedOptions.price,
				},
			};

			const newBundle: Bundle = {
				key: currentLineItem?.properties?.['_bundle_key'] ?? '_bundle_key',
				base: {
					frame: currentLineItem,
					prescription: { type: RX_TYPE_HANDLE_MAP[rxType], submissionMethod: 'remind' },
					insurance: (pairCareItem || undefined) as NormalizedCartLine | undefined,
				},
			};
			setBundle(newBundle);
		};
		buildFlowPairCareAdapter();
	}, [items, buildFlow]);

	return {
		pairCare,
		isPairCareLoading,
		bundle,
		isMounted,
		callback: setPairCareItem,
	};
};
export default useProtectYourLenses;
