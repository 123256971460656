/* eslint-disable max-lines */
import { useCallback } from 'react';
import cn from 'classnames';
import { m } from 'framer-motion';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useRouter } from 'next/router';
import variables from '@styles/export.module.scss';
import {
	Flex,
	Lozenge,
	NonRx,
	Paragraph,
	Progressive,
	ProtectYourLenses,
	Readers,
	Select,
	SingleVision,
	Title,
	TypographyButton,
} from '@components';
import { formatCurrency } from '@utils/shopify';
import { useBFContext } from '@context';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	LOCALE_CODES,
	LOCALE_DICT,
	READER_STRENGTHS,
	READERS_STRENGTH_COPY,
} from '@constants';
import { RxOption } from '@ts/index';
import { buildFlowPrescriptionVariants } from '@utils/motions';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import styles from '../PrescriptionStep/PrescriptionStep.module.scss';

const PrescriptionIcons = {
	'single-vision': ({ className }) => <SingleVision className={className} />,
	'non-prescription': ({ className }) => <NonRx className={className} />,
	'readers': ({ className }) => <Readers className={className} />,
	'progressives': ({ className }) => <Progressive className={className} />,
};

const Prescriptions = ({ isPairCareVisible }: { isPairCareVisible?: boolean }) => {
	const {
		setStepChange,
		setRxType,
		setReaderRx,
		readerRx,
		rxType,
		step,
		journey,
		rxOptions,
		variantPrices,
		BaseFrameVariant,
		previousBaseFrames,
		hasPreviousBaseFrames,
		setSelectedLenses,
		setLensPackage,
		isQuickAdd,
	} = useBFContext();
	const { locale } = useRouter();
	const isLensesStep = step === BUILD_FLOW_STEPS.PACKAGE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isNotEyeGlasses = journey !== CUSTOMER_JOURNEYS.EYEGLASSES;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	const updateReaderStrength = useCallback(strength => {
		const cleanStr: (typeof READER_STRENGTHS)[number] = strength.replace('+', '');
		setReaderRx(cleanStr);
	}, []);

	const handleClick = useCallback(
		(option: RxOption) => {
			if (isNotEyeGlasses && option.optionValue !== RX_TYPE.READERS) {
				setRxType(option.optionValue);
			} else {
				setRxType(option.optionValue);
				if (!isQuickAdd) {
					setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
				}
			}
			if (isQuickAdd) {
				setSelectedLenses([BASE_FRAME_LENS_OPTIONS.CR39]);
				setLensPackage(null);
			}
		},
		[isLensesStep, isReadersSelected, isQuickAdd]
	);
	const filteredRxOptions =
		isLensesStep && isSunLens && isPairCareVisible ? rxOptions.filter(option => option.optionValue === rxType) : rxOptions;

	const MappedPrescriptions = () => (
		<>
			{filteredRxOptions.map((option: RxOption, index: number) => {
				if (!BaseFrameVariant.data) return null;
				const isOptionSelected = rxType === option.optionValue;
				const showReaderStrength = isReadersSelected && isOptionSelected;
				const isProgressiveSelected = rxType === RX_TYPE.PROGRESSIVE;
				const variantPriceDiff = getVariantPriceDiff({
					lookup: variantPrices,
					current: BaseFrameVariant,
					rxType: option.optionValue,
					sunLensException: isSunLens,
					locale,
					currencyCode: LOCALE_DICT[locale].currencyCode,
				});
				const additionalPrice = isOptionSelected && !isSunLens ? variantPriceDiff.without : variantPriceDiff.with;

				const priceCopy = `+${formatCurrency(additionalPrice, showCurr)}`;
				const classes = cn(styles['rx-list-item'], {
					[styles['hover-disabled']]: isLensesStep,
					[styles['is-readers']]: isReadersSelected,
					[styles['extra-z-index']]: isReadersSelected && option.optionValue === RX_TYPE.READERS,
					[styles['is-pair-care-visible']]:
						isSunLens && isPairCareVisible && isReadersSelected && filteredRxOptions < rxOptions,
				});
				const isPreviouslyOrdered = hasPreviousBaseFrames && previousBaseFrames[0].rxType === option.optionValue;

				let animate = 'initial';
				let initial = 'initial';
				if (isLensesStep) {
					animate = (isOptionSelected && (isReadersSelected || !isSunLens)) || isQuickAdd ? 'active' : 'inactive';
					initial = isOptionSelected || isQuickAdd ? 'active' : 'inactive';
				}

				return (
					<m.div
						key={option.id}
						id={option.id}
						className={classes}
						initial={initial}
						data-prescription-type={option.copy}
						animate={animate}
						variants={buildFlowPrescriptionVariants(index, isQuickAdd)}
						transition={{ type: 'spring', stiffness: 256, damping: 24, duration: 0.25, mass: 1 }} // MODIFIED
						custom={index}
						onClick={() => handleClick(option)}
					>
						<Flex fullHeight fullWidth>
							{PrescriptionIcons[option.id]({ className: styles.rxIcon })}
							<Flex
								align='center'
								gap={2}
								fullWidth
								style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem' }}
							>
								<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
									{!isLensesStep && (
										<Flex align='center' gap={2} position={'relative'} fullWidth>
											<Paragraph className={styles['price']}>{priceCopy}</Paragraph>
										</Flex>
									)}
									<Title>{option.copy}</Title>
									<Flex align='center' gap={2} position={'relative'} fullWidth>
										{isPreviouslyOrdered && (
											<Lozenge
												text='Ordered Previously'
												shape='square'
												color={variables.gray1}
												backgroundColor={variables.blue1}
											/>
										)}
										{option.optionValue === RX_TYPE.PROGRESSIVE && !isProgressiveSelected && (
											<Lozenge
												text='Our Specialty'
												shape='square'
												color={variables.green2}
												backgroundColor={variables.greenLight}
											/>
										)}
										{isOptionSelected ? (
											<Lozenge
												text='Selected'
												shape='square'
												color={variables.green2}
												backgroundColor={variables.greenLight}
											/>
										) : null}
									</Flex>
									{(!isLensesStep || isQuickAdd) && (
										<Paragraph style={{ color: variables.gray4 }}>{option.description}</Paragraph>
									)}
									{isLensesStep && <Paragraph className={styles.selectedText}>{priceCopy}</Paragraph>}
								</Flex>
							</Flex>
						</Flex>
						{showReaderStrength && (
							<div className={styles['readers']} onClick={e => e.stopPropagation()}>
								<Flex justify='between' align='center'>
									<TypographyButton small>{READERS_STRENGTH_COPY}</TypographyButton>
									<Select
										buttonProps={{ size: 'small' }}
										values={READER_STRENGTHS.map(rs => `+${rs}`)}
										handler={updateReaderStrength}
										placeholder={readerRx ? `+${readerRx}` : 'Select'}
									/>
								</Flex>
							</div>
						)}
					</m.div>
				);
			})}
		</>
	);

	if (isQuickAdd) {
		return (
			<RadioGroupPrimitive.Root asChild value={rxType}>
				<MappedPrescriptions />
			</RadioGroupPrimitive.Root>
		);
	}

	return isLensesStep && !isReadersSelected ? null : (
		<>
			<RadioGroupPrimitive.Root asChild value={rxType}>
				<MappedPrescriptions />
			</RadioGroupPrimitive.Root>
			{isPairCareVisible && <ProtectYourLenses />}
		</>
	);
};

export default Prescriptions;
