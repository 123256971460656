import { Caption, Flex, Loading, PairCare } from '@components';
import { useProtectYourLenses } from '@utils/hooks';
import styles from './ProtectYourLenses.module.scss';
interface ProtectYourLensesProps {
	showTitle?: boolean;
	enableDefaultStyles?: boolean;
}

function ProtectYourLenses({ showTitle = true, enableDefaultStyles = true }: ProtectYourLensesProps) {
	const { bundle, pairCare, callback, isMounted, isPairCareLoading } = useProtectYourLenses();
	if (isPairCareLoading || !isMounted) return <Loading />;

	return (
		<Flex column gap={2} fullWidth className={enableDefaultStyles && styles.container}>
			{showTitle && <Caption>Protect Your Lenses</Caption>}
			<PairCare bundle={bundle} pairCare={pairCare} callback={callback} isBuildFlow />
		</Flex>
	);
}
export default ProtectYourLenses;
