import Parser from 'html-react-parser';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { VariantControl } from '@ts/product';
import { About, BaseFrameShapeColor, Button, Flex, Material, Paragraph, Spacer, SwatchController } from '@components';
import { useIsMobile } from '@utils/hooks';
import {
	BASE_FRAME_NAMES,
	COLOR_REFRESH_BASE_FRAMES,
	FRAME_COLORS,
	MEDIUM_WIDTH,
	METAL_BASE_FRAMES,
	NEW_COLORS,
	PRODUCT_TYPES,
} from '@constants';
import styles from '../ProductViewCTA/ProductViewCTA.module.scss';

type ProductViewDescriptionProps = {
	primaryController: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	aboutProps: Parameters<typeof About>[0];
	name: string;
	secondaryController?: VariantControl;
	updateFrameShape?: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	variantsAvailable?: Set<string>;
	colorController?: VariantControl;
	productType?: string;
	selectedMaterial?: string;
	handleMaterialChange?: (material: string, frameColor: string) => void;
	frameShape?: (typeof BASE_FRAME_NAMES)[number];
};

function ProductViewDescription({
	primaryController,
	aboutProps,
	name,
	secondaryController,
	updateFrameShape,
	variantsAvailable,
	colorController,
	productType,
	selectedMaterial,
	handleMaterialChange,
	frameShape,
}: ProductViewDescriptionProps) {
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isHideNewProducts = useFeatureIsOn('is-hide-new-products');
	const baseFrameNames = name.split(' ')[1];
	const filteredOptions = primaryController?.options?.filter(
		option => !NEW_COLORS.includes(option as (typeof NEW_COLORS)[number])
	);

	if (primaryController.controllerType === 'accessory')
		return (
			<>
				<Spacer size={'0.8rem'} />
				<Paragraph>{aboutProps?.description}</Paragraph>
				<Spacer size={'1.6rem'} />
			</>
		);
	const shouldShowClassic =
		COLOR_REFRESH_BASE_FRAMES.includes(baseFrameNames as (typeof COLOR_REFRESH_BASE_FRAMES)[number]) && !isHideNewProducts;

	return (
		<>
			{primaryController.controllerType === 'shape' ? (
				<>
					{aboutProps?.descriptionHtml ? (
						<>
							<Spacer size={'0.8rem'} />
							<Paragraph className={styles.description}>{Parser(aboutProps?.descriptionHtml)}</Paragraph>
							<Spacer size={'1.6rem'} />
						</>
					) : aboutProps?.description ? (
						<>
							<Spacer size={'0.8rem'} />
							<Paragraph>{aboutProps?.description}</Paragraph>
							<Spacer size={'1.6rem'} />
						</>
					) : null}
					{!isMobile && (
						<BaseFrameShapeColor
							callback={updateFrameShape}
							primaryController={primaryController}
							variantsAvailable={variantsAvailable}
							colorController={colorController}
							productType={productType}
							frameShape={frameShape}
						/>
					)}
				</>
			) : (
				<Flex column gap={3} style={{ marginBottom: '1.6rem' }}>
					{!isMobile && (
						<>
							<Paragraph>{aboutProps?.description}</Paragraph>
							<Spacer size={'1.6rem'} />
						</>
					)}
					{primaryController.variantImages && (
						<>
							<SwatchController
								alignment='left'
								options={filteredOptions}
								callback={primaryController?.callback}
								selected={primaryController?.selected}
								type={
									primaryController?.controllerType as Exclude<typeof primaryController.controllerType, 'shape'>
								}
								title={primaryController?.title}
								label={shouldShowClassic && 'Classic'}
								name={name}
								excludeColorLabel={!filteredOptions.includes(primaryController.selected)}
							/>
							{filteredOptions?.length !== primaryController?.options.length && (
								<SwatchController
									alignment='left'
									options={NEW_COLORS}
									callback={primaryController?.callback}
									selected={primaryController?.selected}
									type={
										primaryController?.controllerType as Exclude<
											typeof primaryController.controllerType,
											'shape'
										>
									}
									title={primaryController?.title}
									label={'Limited Edition'}
									name={name}
									excludeColorLabel={!NEW_COLORS.includes(primaryController.selected as FRAME_COLORS)}
								/>
							)}
						</>
					)}
					{productType.includes(PRODUCT_TYPES.BASE_FRAME_SR) &&
						METAL_BASE_FRAMES.includes(baseFrameNames as (typeof METAL_BASE_FRAMES)[number]) && (
							<Material callback={handleMaterialChange} selectedMaterial={selectedMaterial} />
						)}
					{secondaryController && (
						<SwatchController
							alignment='left'
							options={secondaryController.options}
							callback={secondaryController.callback}
							selected={secondaryController.selected}
							type={secondaryController.controllerType as Exclude<typeof primaryController.controllerType, 'shape'>}
							title={secondaryController.title}
							name={name}
						/>
					)}
				</Flex>
			)}
		</>
	);
}

export default ProductViewDescription;
